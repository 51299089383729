@import "../../styles/_chunk";

.footer-banner {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 1.5rem 0.5rem;
  display: flex;
  align-items: center;
  position: relative;
  min-height: 380px;

  @include media-breakpoint-up(md) {
    min-height: 180px;
    padding: 0;
  }

  @include media-breakpoint-up(lg) {
    height: 200px;
  }

  @include media-breakpoint-up(xl) {
    height: 220px;
    background-size: 1500px;
  }

  @include media-breakpoint-up(xxl) {
    height: 260px;
    background-size: 1800px;
  }

  @media only screen and (min-width: 1800px) {
    background-size: cover;
  }

  .banner-content-container {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 350px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
      min-height: unset;
    }

    .logo-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      z-index: 1;

      @include media-breakpoint-up(md) {
        width: 40%;
        margin-left: -1rem;
      }

      @include media-breakpoint-up(lg) {
        width: 33.333%;
        margin-left: -1.5rem;
      }

      @include media-breakpoint-up(xl) {
        margin-left: -2rem;
      }

      @include media-breakpoint-up(xxl) {
        margin-left: -2.5rem;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        min-height: 150px;

        @include media-breakpoint-down(xs) {
          max-width: 260px;
        }

        @include media-breakpoint-down(sm) {
          max-width: 320px;
        }
      }
    }

    .character-img-container {
      display: none;

      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        height: 100%;
      }
    }

    .message-container {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 40%;
        justify-content: flex-end;
      }

      @include media-breakpoint-up(lg) {
        width: 33.333%;
      }

      .message-content {
        color: $white;
        z-index: 1;
        text-align: center;
        font-weight: 700;

        @include media-breakpoint-up(md) {
          text-align: right;
          width: 270px;
        }

        @include media-breakpoint-up(lg) {
          width: 270px;
        }

        @include media-breakpoint-up(xl) {
          width: 320px;
        }

        @include media-breakpoint-up(xxl) {
          width: 380px;
        }

        .line-one {
          font-size: 20px;
          text-transform: uppercase;
          line-height: 2rem;

          @include media-breakpoint-up(sm) {
            font-size: 26px;
          }

          @include media-breakpoint-up(md) {
            font-size: 24px;
          }

          @include media-breakpoint-up(lg) {
            font-size: 22px;
          }

          @include media-breakpoint-up(xl) {
            font-size: 25px;
          }

          @include media-breakpoint-up(xxl) {
            font-size: 30px;
          }
        }

        .line-two {
          line-height: 1.875rem;
          font-size: 16px;
          padding-top: 0.3rem;

          @include media-breakpoint-up(sm) {
            font-size: 19px;
          }

          @include media-breakpoint-up(lg) {
            font-size: 16px;
          }

          @include media-breakpoint-up(xl) {
            font-size: 21px;
          }

          @include media-breakpoint-up(xxl) {
            font-size: 25px;
          }

          span.end-words {
            white-space: nowrap;
          }
        }
      }
    }
  }

  &.flip-images {
    .banner-content-container {
      @include media-breakpoint-up(lg) {
        justify-content: flex-end;
      }

      .character-img-container {
        justify-content: flex-start;
      }
    }
  }
}

.fake-footer-banner {
  min-height: 380px;

  @include media-breakpoint-up(md) {
    padding: 0;
    min-height: 180px;
  }

  @include media-breakpoint-up(lg) {
    height: 200px;
  }

  @include media-breakpoint-up(xl) {
    height: 220px;
  }

  @include media-breakpoint-up(xxl) {
    height: 260px;
  }
}

.preview-footer-banner {
  .mobile-preview-footer-banner-container {
    width: 379px;
    border: 2px solid $white;

    @include theme(light) {
      border: 2px solid $black;
    }

    .footer-banner {
      background-size: cover;
      min-height: 380px;
      padding: 1.5rem 0.5rem;
      height: unset;

      .banner-content-container {
        flex-direction: column;
        justify-content: unset;

        .logo-container {
          width: 100%;
          padding: 1rem;
          margin-left: unset;

          img {
            width: 100%;
          }
        }

        .character-img-container {
          display: none;
        }

        .message-container {
          justify-content: center;
          width: 100%;

          .message-content {
            width: unset;
            text-align: center;

            .line-one {
              font-size: 20px;
            }

            .line-two {
              font-size: 16px;
            }
          }
        }
      }

      &.flip-images {
        .banner-content-container {
          justify-content: unset !important;
        }
      }
    }

    .hero-container {
      .bg-img {
        width: 150%;
        min-width: unset;
      }
    }

    .mobile-takeover {
      position: relative;
      padding-bottom: 33.333%;
      display: block;
    }

    .center-img-container {
      margin: 0;
    }

    .solid-hero-container {
      height: 118px;

      .solid-center-img-container {
        img {
          height: 118px;
        }
      }
    }
  }

  .desktop-preview-section {
    margin-top: 2rem;
  }
}
